import { configureStore } from "@reduxjs/toolkit";
import darkModeReducer from "./features/darkModeSlice";
import statusUserReducer from "./features/statusUserSlice";

const store = configureStore({
  reducer: {
   darkMode: darkModeReducer,
   statusUser: statusUserReducer
  },
});

export default store;