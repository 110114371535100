import { useState, useEffect } from "react";
import { requestForToken, onMessageListener } from "../../firebase";
import AboutMessage from "./AboutMessage";
import { useSelector } from "react-redux";

const Notification = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const darkMode = useSelector((state) => state.darkMode);

  useEffect(() => {
    requestForToken();
  }, []);

  useEffect(() => {
    if (notification?.title) {
      AboutMessage({ status: "info", message: notification?.title, darkMode });
    }
  }, [notification, darkMode]);

  onMessageListener()
  .then((payload) => {
    setNotification({
      title: payload?.notification?.title,
    });
  })
  .catch((err) => console.log("failed: ", err));

  return null;
};

export default Notification;
