import styles from "../../../styles/styles.module.css";
import DefaultField from "../DefaultField";
import { Formik, Form, ErrorMessage } from "formik";
import SelectReportType from "../select/SelectReportType";
import ImageUploader from "../ImageUploader";
import { useState } from "react";
import AboutMessage from "../AboutMessage";
import { useSelector } from "react-redux";
import axiosConfig from "../../../config/axiosConfig";
import Cookies from "js-cookie";
import { CircularProgress } from "@mui/material";

const ModalSubmitReport = ({
  load_id,
  offer_load_id,
  setShowModalSubmitReport,
  fetchReports
}) => {
  // console.log(load_id)
  // console.log(offer_load_id);
  const darkMode = useSelector((state) => state.darkMode);
  const statusUser = useSelector((state) => state.statusUser);

  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataSelectReportType = ["pickup", "carrying", "dropoff"];

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-60">
      <div id="modalstylesubmitreport" className="modal font-extralight">
        <div id="modalcontentstylesubmitreport" className="modal-content">
          <div className="w-full h-auto flex flex-col justify-between items-start gap-2">
            <p className="text-black text-base font-normal pb-2">Send Report</p>
            <Formik
              initialValues={{
                load_id: "",
                offer_load_id: "",
                first_name: "",
                last_name: "",
                phone: "",
                description: "",
                citizenship: "",
                location: "",
                report_type: "",
                signature: "",
                images: "",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.first_name) {
                  errors.first_name = "Please enter your first name";
                } else if (!/^[A-Za-z]+$/i.test(values.first_name)) {
                  errors.first_name =
                    "should only contain Latin letters a - z - A - Z";
                }
                if (!values.last_name) {
                  errors.last_name = "Please enter your last_name";
                } else if (!/^[A-Za-z]+$/i.test(values.last_name)) {
                  errors.last_name =
                    "should only contain Latin letters a - z - A - Z";
                }
                if (!values.last_name) {
                  errors.last_name = "Please enter your last_name";
                } else if (!/^[A-Za-z]+$/i.test(values.last_name)) {
                  errors.last_name =
                    "should only contain Latin letters a - z - A - Z";
                }
                if (!values.phone) {
                  errors.phone = "Please enter your phone";
                } else if (!/^[0-9]+$/i.test(values.phone)) {
                  errors.phone = "should only contain Latin letters 0 - 9";
                }
                if (!values.report_type) {
                  errors.report_type = "Please enter your report type";
                }
                return errors;
              }}
              onSubmit={async (values) => {
                values.load_id = load_id;
                values.offer_load_id = offer_load_id;
                values.signature = images;
                values.description = description;
                values.images = images;

                if (values.description === "") {
                  AboutMessage({
                    status: "error",
                    message: "Please enter description",
                    darkMode,
                  });
                  return;
                }

                if (images.length === 0) {
                  AboutMessage({
                    status: "error",
                    message: "Please upload file load image",
                    darkMode,
                  });
                  return;
                }

                if (statusUser === "offline") {
                  AboutMessage({
                    status: "error",
                    message: "You are offline",
                    darkMode,
                  });
                  return;
                }

                const locationCookie = Cookies.get("location");

                const locationParse = JSON.parse(locationCookie);

                const lat = locationParse.lat;
                const lng = locationParse.lng;
                const locationString = `${lat},${lng}`;
                values.location = locationString;

                try {
                  setLoading(true);
                  const token = Cookies.get("token");
                  const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
                  const endpoint = process.env.REACT_APP_SUBMIT_REPORT;
                  const axiosInstance = axiosConfig(baseURL, token);

                  const config = {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  };

                  const response = await axiosInstance.post(
                    endpoint,
                    values,
                    config
                  );

                  if (response.status === 200) {
                    setShowModalSubmitReport(false);
                    // window.location.reload();
                    fetchReports()
                    AboutMessage({
                      status: "success",
                      message: response.data.message,
                      darkMode,
                    });
                  }
                } catch (error) {
                  // console.log(error);
                  if (error.response) {
                    const errorsMessage = error.response.data.message;
         
                    AboutMessage({
                      status: "error",
                      message: errorsMessage,
                      darkMode,
                    });
                  } else {
                    console.log(error.message);
                  }
                } finally {
                  setLoading(false);
                }
              }}
            >
              {({ setFieldValue }) => (
                <Form className="w-full h-auto flex flex-col gap-2">
                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="first_name"
                    minLength="0"
                    maxLength="255"
                    placeholder="First Name"
                  />
                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="last_name"
                    minLength="0"
                    maxLength="255"
                    placeholder="Last Name"
                  />
                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="phone"
                    minLength="0"
                    maxLength="255"
                    placeholder="Phone"
                  />
                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="citizenship"
                    minLength="0"
                    maxLength="255"
                    placeholder="Citizenship"
                  />
                  <SelectReportType
                    title="Report Type"
                    name="report_type"
                    data={dataSelectReportType}
                    onChange={(selectedValue) =>
                      setFieldValue("report_type", selectedValue)
                    }
                  />
                  <ErrorMessage
                    name="report_type"
                    component="div"
                    className="w-full h-auto text-sm text-red-600 font-extralight max-sm:text-xs"
                  />
                  <textarea
                    className={styles.stylefield}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Description"
                  ></textarea>
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="w-full h-auto text-sm text-red-600 font-extralight max-sm:text-xs"
                  />
                  <ImageUploader
                    title="Load Image & Truck Image"
                    selectedFiles={images}
                    setSelectedFiles={setImages}
                  />
                  <div className="w-full h-auto flex flex-row justify-center items-center gap-1 pt-10">
                    <button
                      className="w-1/2 h-auto flex flex-row justify-center items-center px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-extralight shadow-md max-sm:text-xs max-md:text-sm"
                      type="submit"
                    >
                      {loading ? (
                        <CircularProgress size={16} sx={{ color: "black" }}/>
                      ) : (
                        "Submit"
                      )}
                    </button>
                    <button
                      className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-extralight shadow-md max-sm:text-xs max-md:text-sm"
                      type="button"
                      onClick={() => setShowModalSubmitReport(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSubmitReport;
