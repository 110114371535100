import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Cookies from "js-cookie";
import axiosConfig from "./config/axiosConfig";

var firebaseConfig = {
  apiKey: "AIzaSyB0HuxIV74rCJjEXbjrfvkV0N7ZN68akp8",
  appId: "1:549967541659:web:af32ea2ca9d244100c9d3e",
  authDomain: "vue-web-app-7bfca.firebaseapp.com",
  measurementId: "G-96DHB0734V",
  messagingSenderId: "549967541659",
  projectId: "vue-web-app-7bfca",
  storageBucket: "vue-web-app-7bfca.appspot.com",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = async () => {
  return getToken(messaging)
    .then(async (currentToken) => {
      if (currentToken) {
        // console.log("token :", currentToken);
        if (currentToken) {
          const token = Cookies.get("token");
          const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
          const endpoint = process.env.REACT_APP_UPDATE_DEVICE_TOKEN;
          const axiosInstance = axiosConfig(baseURL, token);

          const values = {
            device_token: currentToken,
          };

          const response = await axiosInstance.post(endpoint, values);
          console.log(response.status);
        }
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      // console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
