import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FaTruckLoading, FaHome, FaUser } from "react-icons/fa";
import { FaTruckFast } from "react-icons/fa6";
import axiosConfig from "../../config/axiosConfig";
import Cookies from "js-cookie";
import AboutMessage from "./AboutMessage";
import { useDispatch, useSelector } from "react-redux";
import { setStatusUser } from "../../redux_toolkit/features/statusUserSlice.js";
import { FaPowerOff } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import getLocation from "../../utils/getLocation.jsx";
import { CircularProgress } from "@mui/material";
import ModalScreenProtector from "./dialogs/ModalScreenProtector.jsx";

const MobileMenu = () => {
  const { pathname } = useLocation();
  const parts = pathname.split("/");
  const primaryID = parts[2];
  const subID = parts[3];

  const dispatch = useDispatch();
  const statusUser = useSelector((state) => state.statusUser);
  const darkMode = useSelector((state) => state.darkMode);
  const [intervalId, setIntervalId] = useState(null);
  const [loading, setLoading] = useState(false);

  // const offerLoadId = Cookies.get("offerLoadId");
  // const offerLoadIdJSON = JSON.parse(offerLoadId);
  // console.log(offerLoadIdJSON)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get("token");
        const baseURL = "https://atoz-register.metariom.shop";
        const endpoint = "/api/v1/user/is-online";
        const axiosInstance = axiosConfig(baseURL, token);

        const response = await axiosInstance.get(endpoint);
        // console.log("statusUser :",response.data.data)
        if (response.status === 200 && response.data.data === "online") {
          dispatch(setStatusUser("online"));

          getUserLocation();
        }
      } catch (error) {
        // console.log(error);
        AboutMessage({ status: "error", message: error.message, darkMode });
        if (error.status === 429) {
          AboutMessage({
            status: "error",
            message: error.response.message,
            darkMode,
          });
        }
      }
    };

    fetchData();
  }, [dispatch, darkMode]);

  const detectOnlineStatus = async () => {
    setLoading(true);
    try {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = "/api/v1/user/online-offline";

      const axiosInstance = axiosConfig(baseURL, token);

      const values = {
        online: statusUser === "online" ? "no" : "yes",
      };

      const response = await axiosInstance.post(endpoint, values);
      if (response.status === 200) {
        dispatch(setStatusUser(statusUser === "online" ? "offline" : "online"));
        AboutMessage({
          status: "success",
          message: response.data.message,
          darkMode,
        });
        if (response.data.message === "You are online now") {
          dispatch(setStatusUser("online"));
          getUserLocation();
          getLocation();
          setLoading(false);
        } else {
          dispatch(setStatusUser("offline"));
          setLoading(false);
        }
      } else {
        throw new Error("Failed to send status user");
      }
    } catch (error) {
      // console.log(error);
      AboutMessage({
        status: "error",
        message: error.response.data.message,
        darkMode,
      });
    } finally {
      setLoading(false);
    }
  };

  const getUserLocation = () => {
    let prevLat = null;
    let prevLng = null;

    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          if (latitude !== prevLat || longitude !== prevLng) {
            sendUserLocation(latitude, longitude);

            prevLat = latitude;
            prevLng = longitude;

            const id = setInterval(() => {
              sendUserLocation(latitude, longitude);
            }, 5 * 60 * 1000);
            setIntervalId(id);
            return () => clearInterval(intervalId);
          }
        },
        (error) => {
          AboutMessage({
            status: "error",
            message: error.message,
            darkMode,
          });
          // console.log(error);
          dispatch(setStatusUser(!statusUser));
        }
      );
    } else {
      AboutMessage({
        message: "Geolocation is not supported by this browser",
        darkMode,
      });
    }
  };

  const sendUserLocation = async (latitude, longitude) => {
    const location = {
      lat: latitude,
      lng: longitude,
    };
    const locationJSON = JSON.stringify(location);
    Cookies.set("location", locationJSON);
    try {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = "/api/v1/user/update-location";
      const axiosInstance = axiosConfig(baseURL, token);

      const location = {
        lat: latitude,
        lng: longitude,
      };

      await axiosInstance.post(endpoint, location);
      // console.log(res.status);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // if(loading){
  //   return <ModalScreenProtector />
  // }

  return (
    <nav className="w-screen h-1/12 flex flex-row justify-center items-center bg-amber-400 p-5 text-base rounded-t-md text-black md:hidden lg:hidden xl:hidden 2xl:hidden">
      <div className="w-full h-full flex flex-row justify-around items-center gap-6 font-normal uppercase max-sm:gap-2">
        <NavLink
          to={"/"}
          className={
            pathname === "/"
              ? "bg-[#75757538] rounded-full p-3 border-black"
              : "p-3"
          }
        >
          <FaHome className="text-[1.75rem] relative bottom-[1px] max-sm:text-[1.5rem]" />
        </NavLink>
        <NavLink
          to={"/loads"}
          className={
            pathname === "/loads" || pathname === `/load/${primaryID}` || pathname === "/loads/reports" || pathname === `/loads/report/${subID}`
              ? "bg-[#75757538] rounded-full p-3 border-black"
              : "p-3"
          }
        >
          <FaTruckLoading className="text-[2rem] max-sm:text-[1.55rem]" />
        </NavLink>
        <button
          type="button"
          className={`h-12 w-12 rounded-full shadow-md p-3 flex flex-row justify-center items-center text-3xl ${
            statusUser ? "bg-green-50" : "bg-red-50"
          }`}
          onClick={detectOnlineStatus}
        >
          {statusUser === "online" ? (
            loading ? (
              <CircularProgress
                size={20}
                thickness={5}
                sx={{ color: "#fcb910" }}
              />
            ) : (
              <FaPowerOff className="text-green-600" />
            )
          ) : loading ? (
            <CircularProgress
              size={20}
              thickness={5}
              sx={{ color: "#fcb910" }}
            />
          ) : (
            <FaPowerOff className="text-red-600" />
          )}
        </button>
        <NavLink
          to={"/truck"}
          className={
            pathname === "/truck"
              ? "bg-[#75757538] rounded-full p-3 border-black"
              : "p-3"
          }
        >
          <FaTruckFast className="text-[2rem] max-sm:text-[1.55rem]" />
        </NavLink>
        <NavLink
          to={"/profile"}
          className={
            pathname === "/profile"
              ? "bg-[#75757538] rounded-full p-3 border-black"
              : "p-3"
          }
        >
          <FaUser className="text-[1.75rem] relative bottom-[2px] max-sm:text-[1.35rem]" />
        </NavLink>
      </div>
      {loading && <ModalScreenProtector />}
    </nav>
  );
};

export default MobileMenu;
