import { useState } from "react";
import { Formik, Form } from "formik";
import DefaultField from "../../module/DefaultField";
import PasswordField from "../../module/PasswordField";
import axiosConfig from "../../../config/axiosConfig";
import ModalVerifyEmail from "../../module/dialogs/ModalVerifyEmail";
import AboutMessage from "../../module/AboutMessage";
import { useNavigate } from "react-router-dom";

const RegisterForm = ({ setActiveStep, showModal, setShowModal }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
          password_confirmation: "",
        }}
        validate={(values) => {
          const errors = {};
          setEmail(values.email);
          if (!values.email) {
            errors.email = "Please enter your email";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "The email address is invalid";
          }

          if (!values.password) {
            errors.password = "Please enter your password";
          } else if (!/^[A-Za-z0-9._%+-=()&?!#$`^"'{}]+$/.test(values.password)) {
            errors.password =
              "The password must be a combination of upper and lower case letters and numbers";
          }
          if (!values.password_confirmation) {
            errors.password_confirmation = "Please enter your password";
          } else if (!/^[A-Za-z0-9._%+-=()&?!#$`^"'{}]+$/.test(values.password_confirmation)) {
            errors.password_confirmation =
              "The password must be a combination of upper and lower case letters and numbers";
          }
          if (values.password !== values.password_confirmation) {
            errors.password_confirmation = "Do not match";
          }
          return errors;
        }}
        onSubmit={async (values) => {
          localStorage.setItem("email", values.email);
          const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
          const endpoint = process.env.REACT_APP_REGISTER_URL;
          const axiosInstance = axiosConfig(baseURL);

          try {
            const response = await axiosInstance.post(endpoint, values);
            response.data.status === "success" && setShowModal(true);
          } catch (error) {
            if (error.response) {
              const errorsObject = error.response.data.message;
              Object.entries(errorsObject).forEach(([key, errorMessages]) => {
                errorMessages.forEach((errorMessage) => {
                  AboutMessage({ status: "error", message: errorMessage });
                  if (errorMessage === "The email has already been taken.") {
                    navigate("/login");
                    return;
                  }
                });
              });
            }
          }
        }}
      >
        {() => (
          <Form className="w-6/12 h-auto flex flex-col gap-2 font-medium max-sm:w-10/12 max-md:w-8/12">
            <DefaultField
              stylefield="w-full flex flex-row justify-start items-center rounded-md p-3 text-white text-base outline-none bg-[#111827a6] placeholder:text-white max-sm:text-xs"
              type="email"
              name="email"
              minLength="0"
              maxLength="255"
              placeholder="Email"
            />
            <PasswordField
              name="password"
              minLength="6"
              maxLength="255"
              placeholder="Password"
            />
            <PasswordField
              name="password_confirmation"
              minLength="6"
              maxLength="255"
              placeholder="Confirm password"
            />
            <button
              type="button"
              className="flex flex-row justify-start items-center gap-1 font-extralight text-base text-white max-md:text-sm max-sm:text-xs"
              onClick={() => navigate("/login")}
            >
              <span>Have an accoint ?</span>
              <span className="text-amber-400">Login here</span>
            </button>
            <div className="w-full h-auto flex flex-col gap-2 text-base pt-10 text-white font-medium max-md:font-extralight">
              <button
                type="submit"
                className="w-full h-auto flex flex-row justify-center items-center gap-1 bg-gradient-to-l from-amber-500 to-amber-400 rounded-md p-2 max-sm:text-xs"
              >
                Register
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {showModal && (
        <ModalVerifyEmail
          email={email}
          setShowModal={setShowModal}
          otp={otp}
          setOtp={setOtp}
          setActiveStep={setActiveStep}
        />
      )}
    </>
  );
};

export default RegisterForm;
