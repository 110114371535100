import { useSelector } from "react-redux";
import { HiStatusOffline, HiStatusOnline } from "react-icons/hi";

const DisplayStatusUser = () => {
  const darkMode = useSelector((state) => state.darkMode);
  const statusUser = useSelector((state) => state.statusUser);
  
  return (
    <div
      className={`w-full h-20 flex flex-row justify-center items-center p-4 ${
        darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
      }`}
    >
      <div
        className={`w-10/12 h-auto flex flex-row justify-start items-center gap-2 rounded-md p-4 max-md:w-full ${
          darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
        }`}
      >
        <p className="cursor-default font-normal uppercase max-md:text-sm max-sm:text-xs">
          {statusUser === "online" ? "Online" : "Offline"}
        </p>
        <span className="text-xl relative top-[0.75px]">
          {statusUser === "online" ? (
            <HiStatusOnline className="text-green-600" />
          ) : (
            <HiStatusOffline className="text-red-600" />
          )}
        </span>
      </div>
    </div>
  );
};

export default DisplayStatusUser;
