import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DesktopNavber from "../components/module/DesktopNavber";
import HomeDetails from "../components/template/HomeDetails";
import MobileMenu from "../components/module/MobileMenu";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axiosConfig from "../config/axiosConfig";
import Spinner from "../components/module/Spinner";
import DisplayStatusUser from "../components/module/DisplayStatusUser";
import { useSelector } from "react-redux";
// import { requestForToken } from "../firebase";

const Home = () => {
  const navigate = useNavigate();
  const darkMode = useSelector((state) => state.darkMode);
  const [loading, setLoading] = useState(true);
  localStorage.removeItem("email");

  // useEffect(() => {
  //   requestForToken();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get("token");
      if (token) {
        const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
        const endpoint = process.env.REACT_APP_PERSONAL_INFO_URL;
        const axiosInstance = axiosConfig(baseURL, token);
        try {
          const response = await axiosInstance.get(endpoint);
          if (response.data.data.register_level_title !== "complete") {
            navigate("/register");
          } else {
            setLoading(false);
          }
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      } else {
        navigate("/login");
      }
    };
    fetchData();
  }, [navigate]);

  if (loading) {
    return (
      <div className={`h-screen w-full flex flex-col justify-center items-center ${
        darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
      }`}>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <div
        className={`w-full h-auto flex flex-col justify-start items-center ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <DesktopNavber />
        <div className="w-full min-h-screen flex flex-col justify-start items-center gap-2 pb-28 overflow-auto">
          <DisplayStatusUser />
          <HomeDetails />
        </div>
        <div className="fixed bottom-0 left-0 right-0">
          <MobileMenu />
        </div>
      </div>
    </>
  );
};

export default Home;
