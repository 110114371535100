import axios from "axios";

const axiosConfig = (baseURL , token) => {
  const axiosInstance = axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  });
  return axiosInstance;
};

export default axiosConfig;
