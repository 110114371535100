import { Helmet } from "react-helmet";
import DesktopNavber from "../components/module/DesktopNavber";
import MobileMenu from "../components/module/MobileMenu";
import DisplayStatusUser from "../components/module/DisplayStatusUser";
import { useSelector } from "react-redux";

const Truck = () => {
  const darkMode = useSelector((state) => state.darkMode);
  return (
    <>
      <Helmet>
        <title>Truck</title>
      </Helmet>
      <div className={`w-full h-auto flex flex-col justify-start items-center ${
        darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
      }`}>
        <DesktopNavber />
        <div className="w-full min-h-screen flex flex-col justify-start items-center gap-2 pb-28 overflow-auto">
          <DisplayStatusUser />
        </div>
        <div className="fixed bottom-0 left-0 right-0">
          <MobileMenu />
        </div>
      </div>
    </>
  );
};

export default Truck;
