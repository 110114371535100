import { Map as PigeonMap, Marker, ZoomControl } from "pigeon-maps";
// import { FaTruck, FaBox } from "react-icons/fa6";
import { RiFullscreenFill, RiFullscreenExitLine } from "react-icons/ri";

const LocationMap = ({ loadData, isModalOpen, setIsModalOpen }) => {
  const loadMarker = loadData.load_pick_up_at_location
    .split(",")
    .map((item) => parseFloat(item.trim()));
  const destinationMarker = loadData.load_deliver_to_location
    .split(",")
    .map((item) => parseFloat(item.trim()));

  const fullScreen = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <PigeonMap defaultCenter={loadMarker} defaultZoom={6} mouseEvents={true}>
      <button
        type="button"
        onClick={fullScreen}
        className="absolute bottom-3 left-3 text-lg text-gray-600 bg-white rounded-sm shadow p-1"
      >
        {isModalOpen ? <RiFullscreenExitLine /> : <RiFullscreenFill />}
      </button>
      <ZoomControl />
      <Marker anchor={destinationMarker}>
        {/* <FaTruck className="text-2xl text-black" /> */}
      </Marker>
      <Marker anchor={loadMarker}>
        {/* <FaBox className="text-2xl text-black" /> */}
      </Marker>
    </PigeonMap>
  );
};

export default LocationMap;
