import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import DesktopNavber from "../../components/module/DesktopNavber";
import MobileMenu from "../../components/module/MobileMenu";
import { useSelector } from "react-redux";
import axiosConfig from "../../config/axiosConfig";
import ListLoadCards from "../../components/template/ListLoadCards";
import DisplayStatusUser from "../../components/module/DisplayStatusUser";
import AboutMessage from "../../components/module/AboutMessage";

const Loads = () => {
  const darkMode = useSelector((state) => state.darkMode);
  const [data, setData] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = process.env.REACT_APP_PERSONAL_INFO_URL;
      const axiosInstance = axiosConfig(baseURL, token);

      try {
        const response = await axiosInstance.get(endpoint);
        setUserId(response.data.data.id);
      } catch (error) {
        console.error(error);
        AboutMessage({ status: "error", message: error.message, darkMode });
      }
    };

    fetchData();
  }, [darkMode]);

  useEffect(() => {
    const fetchData = async () => { 
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_LOADAL_BASE_URL;
      const endpoint = `/api/v1/user/${userId}/get-loads`;
      const axiosInstance = axiosConfig(baseURL, token);
      try {
        if (userId !== "") {
          const response = await axiosInstance.get(endpoint);
          response.status === 200 && setData(response.data.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [userId]);

  return (
    <>
      <Helmet>
        <title>Loads</title>
      </Helmet>
      <div className="w-full h-screen flex flex-col justify-start items-center">
        <DesktopNavber />
        <div
          className={`w-full h-auto flex flex-col justify-between items-center font-extralight ${
            darkMode ? "bg-gray-900 text-white" : "bg-[#F1F5F9] text-black"
          }`}
        >
          <div className="w-full h-auto flex flex-col justify-start items-center pb-28">
            <div className="w-full h-auto fixed top-0 left-0 right-0 z-10 ">
              <DisplayStatusUser />
            </div>
            <div className="w-full h-auto pt-20 z-0">
              <ListLoadCards data={data} />
            </div>
          </div>
          <div className="fixed bottom-0 left-0 right-0">
            <MobileMenu />
          </div>
        </div>
      </div>
    </>
  );
};

export default Loads;
