import { useEffect, useState } from "react";
import DefaultField from "../../module/DefaultField";
import { Formik, Form, ErrorMessage } from "formik";
import SelectTruckType from "../../module/select/SelectTruckType";
import styles from "../../../styles/styles.module.css";
import axiosConfig from "../../../config/axiosConfig";
import ImageUploader from "../../module/ImageUploader";
import AboutMessage from "../../module/AboutMessage";
import Cookies from "js-cookie";
import { CircularProgress } from "@mui/material";

const TruckInfo = ({ setActiveStep }) => {
  const [trucktype, setTrucktype] = useState("");
  const [registrationImage, setRegistrationImage] = useState([]);
  const [insuranceImage, setInsuranceImage] = useState([]);
  const [truckImages, setTruckImages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = process.env.REACT_APP_GET_TRUCK_TYPE;
      const axiosInstance = axiosConfig(baseURL, token);

      try {
        const response = await axiosInstance.get(endpoint);
        response.status === 200 && setTrucktype(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="w-full h-auto flex flex-col justify-center items-center bg-[#F1F5F9] text-black font-extralight py-10">
      <div className="w-11/12 h-auto flex flex-col justify-center items-start gap-10 bg-[#E2E8F0] rounded-md p-8">
        <div className="flex flex-row justify-center items-end gap-2">
          <h1 className="text-xl uppercase max-sm:text-sm">Register</h1>
          <p className="text-xl text-[#1976D2] uppercase max-sm:text-sm">
            Truck Info
          </p>
        </div>
        <Formik
          initialValues={{
            truck_name: "",
            truck_type: "",
            ownership: "",
            vin_number: "",
            year_vehicle_manufacture: "",
            mileage: "",
            max_pallets: "",
            max_carriable_weight: "",
            vehicle_length: "",
            wheel_width: "",
            vehicle_height: "",
            backdoor_width: "",
            backdoor_height: "",
            sidedoor_width: "",
            sidedoor_height: "",
            registration_image: "",
            insurance_image: "",
            truck_images: "",
          }}
          validate={(values) => {
            const errors = {};

            if (!values.truck_type) {
              errors.truck_type = "Please select truck type";
            }
            if (!values.ownership) {
              errors.ownership = "Please select ownership";
            }
            if (!values.vin_number) {
              errors.vin_number = "Please enter vin number";
            } else if (!/^[0-9-A-Z-a-z]+$/.test(values.vin_number)) {
              errors.vin_number = "Should only contain numbers 0 - 9";
            }
            if (!values.year_vehicle_manufacture) {
              errors.year_vehicle_manufacture =
                "Please enter year vehich manufacture";
            } else if (!/^[0-9]+$/.test(values.year_vehicle_manufacture)) {
              errors.year_vehicle_manufacture =
                "Should only contain numbers 0 - 9";
            }
            if (!values.mileage) {
              errors.mileage = "Please enter your Mileage";
            } else if (!/^[0-9]+$/.test(values.mileage)) {
              errors.mileage = "Mileage should only contain numbers 0 - 9";
            }
            if (!values.max_pallets) {
              errors.max_pallets = "Please enter maxPallets";
            } else if (!/^[0-9]+$/.test(values.max_pallets)) {
              errors.max_pallets = "Should only contain numbers 0 - 9";
            }
            if (!values.max_carriable_weight) {
              errors.max_carriable_weight = "Please enter max carriable weight";
            } else if (!/^[0-9]+$/.test(values.max_carriable_weight)) {
              errors.max_carriable_weight = "Should only contain numbers 0 - 9";
            }
            if (!values.vehicle_length) {
              errors.vehicle_length = "Please enter length";
            } else if (!/^[0-9]+$/.test(values.vehicle_length)) {
              errors.vehicle_length = "Should only contain numbers 0 - 9";
            }
            if (!values.wheel_width) {
              errors.wheel_width = "Please enter wheel wheel width";
            } else if (!/^[0-9]+$/.test(values.wheel_width)) {
              errors.wheel_width = "Should only contain numbers 0 - 9";
            }
            if (!values.vehicle_height) {
              errors.vehicle_height = "Please enter height";
            } else if (!/^[0-9]+$/.test(values.vehicle_height)) {
              errors.vehicle_height = "Should only contain numbers 0 - 9";
            }
            if (!values.backdoor_width) {
              errors.backdoor_width = "Please enter width";
            } else if (!/^[0-9]+$/.test(values.backdoor_width)) {
              errors.backdoor_width = "Should only contain numbers 0 - 9";
            }
            if (!values.backdoor_height) {
              errors.backdoor_height = "Please enter height";
            } else if (!/^[0-9]+$/.test(values.backdoor_height)) {
              errors.backdoor_height = "Should only contain numbers 0 - 9";
            }
            if (!values.sidedoor_width) {
              errors.sidedoor_width = "Please enter Width";
            } else if (!/^[0-9]+$/.test(values.sidedoor_width)) {
              errors.sidedoor_width = "Should only contain numbers 0 - 9";
            }
            if (!values.sidedoor_height) {
              errors.sidedoor_height = "Please enter height";
            } else if (!/^[0-9]+$/.test(values.sidedoor_height)) {
              errors.sidedoor_height = "Should only contain numbers 0 - 9";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              values.registration_image = registrationImage;
              values.insurance_image = insuranceImage;
              values.truck_images = truckImages;

              if (values.registration_image.length === 0) {
                AboutMessage({
                  status: "error",
                  message: "Please upload file registration image",
                });
                setSubmitting(false);
                return;
              }
              if (values.insurance_image.length === 0) {
                AboutMessage({
                  status: "error",
                  message: "Please upload file insurance image",
                });
                setSubmitting(false);
                return;
              }
              if (values.truck_images.length === 0) {
                AboutMessage({
                  status: "error",
                  message: "Please upload file truck images",
                });
                setSubmitting(false);
                return;
              }

              setLoading(true);
              const token = Cookies.get("token");
              const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
              const endpoint = process.env.REACT_APP_TRUCK_INFO_STORE_URL;
              const axiosInstance = axiosConfig(baseURL, token);

              const config = {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              };

              const response = await axiosInstance.post(
                endpoint,
                values,
                config
              );
              
              if (response.status === 200) {
                setActiveStep("driver-licence");
              }
            } catch (error) {
              console.error(error);
              if (error.response) {
                const errorsObject = error.response.data.message;
                Object.entries(errorsObject).forEach(([key, errorMessages]) => {
                  errorMessages.forEach((errorMessage) => {
                    AboutMessage({ status: "error", message: errorMessage });
                  });
                });
              }
            } finally {
              setLoading(false);
            }
          }}
        >
          {({ setFieldValue }) => (
            <Form className="w-full h-auto flex flex-col gap-2 font-medium">
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="truck_name"
                minLength="0"
                maxLength="255"
                placeholder="Truck Name"
              />
              <SelectTruckType
                title="Truck Type"
                name="truck_type"
                data={trucktype}
                onChange={(selectedValue) =>
                  setFieldValue("truck_type", selectedValue)
                }
              />
              <ErrorMessage
                name="truck_type"
                component="div"
                className="w-full h-auto text-sm text-red-600 font-extralight max-sm:text-xs"
              />
              <div className="flex flex-col justify-center items-start gap-2 py-2">
                <p className="cursor-default font-extralight text-sm text-[#4b5563] max-sm:text-xs">
                  Type Of Ownership
                </p>
                <div className="flex flex-row justify-center items-center gap-6">
                  <div className="flex flex-row justify-start items-center gap-1">
                    <input
                      type="radio"
                      id="owner"
                      name="ownership"
                      value="Owner"
                      onChange={() => setFieldValue("ownership", "owner")}
                    />
                    <label className="relative bottom-[1px] font-extralight text-[#4b5563] cursor-default max-sm:text-xs">
                      Owner
                    </label>
                  </div>
                  <div className="flex flex-row justify-start items-center gap-1">
                    <input
                      type="radio"
                      id="leasing"
                      name="ownership"
                      value="Leasing"
                      onChange={() => setFieldValue("ownership", "leasing")}
                    />
                    <label className="relative bottom-[1px] font-extralight text-[#4b5563] cursor-default max-sm:text-xs">
                      Leasing
                    </label>
                  </div>
                  <div className="flex flex-row justify-start items-center gap-1">
                    <input
                      type="radio"
                      id="rent"
                      name="ownership"
                      value="Rent"
                      onChange={() => setFieldValue("ownership", "rent")}
                    />
                    <label className="relative bottom-[1px] font-extralight text-[#4b5563] cursor-default max-sm:text-xs">
                      Rent
                    </label>
                  </div>
                </div>
                <ErrorMessage
                  name="ownership"
                  component="div"
                  className="w-full h-auto text-sm text-red-600 font-extralight max-sm:text-xs"
                />
              </div>
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="vin_number"
                minLength="0"
                maxLength="255"
                placeholder="Vin Number"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="year_vehicle_manufacture"
                minLength="0"
                maxLength="255"
                placeholder="Year of Vehicle Manufacture"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="mileage"
                minLength="0"
                maxLength="255"
                placeholder="Mileage"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="max_pallets"
                minLength="0"
                maxLength="255"
                placeholder="Max Pallets"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="max_carriable_weight"
                minLength="0"
                maxLength="255"
                placeholder="Max Carriable Weight"
              />
              <div className="flex flex-col justify-center items-start gap-2">
                <p className="cursor-default font-extralight text-sm text-[#4b5563] max-sm:text-xs">
                  Dimensions ( of Cargo space interior )
                </p>
                <div className="w-full h-auto flex flex-col justify-center items-start gap-2">
                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="vehicle_length"
                    minLength="0"
                    maxLength="255"
                    placeholder="Length ( in )"
                  />
                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="wheel_width"
                    minLength="0"
                    maxLength="255"
                    placeholder="wheel to wheel width ( in )"
                  />
                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="vehicle_height"
                    minLength="0"
                    maxLength="255"
                    placeholder="Height ( in )"
                  />
                </div>
              </div>
              <div className="flex flex-col justify-center items-start gap-2">
                <p className="cursor-default font-extralight text-sm text-[#4b5563] max-sm:text-xs">
                  Back Door
                </p>
                <DefaultField
                  stylefield={styles.stylefield}
                  type="text"
                  name="backdoor_width"
                  minLength="0"
                  maxLength="255"
                  placeholder="Width ( in )"
                />
                <DefaultField
                  stylefield={styles.stylefield}
                  type="text"
                  name="backdoor_height"
                  minLength="0"
                  maxLength="255"
                  placeholder="Height ( in )"
                />
              </div>
              <div className="flex flex-col justify-center items-start gap-2">
                <p className="cursor-default font-extralight text-sm text-[#4b5563] max-sm:text-xs">
                  Side Door
                </p>
                <DefaultField
                  stylefield={styles.stylefield}
                  type="text"
                  name="sidedoor_width"
                  minLength="0"
                  maxLength="255"
                  placeholder="Width ( in )"
                />
                <DefaultField
                  stylefield={styles.stylefield}
                  type="text"
                  name="sidedoor_height"
                  minLength="0"
                  maxLength="255"
                  placeholder="Height ( in )"
                />
              </div>

              <ImageUploader
                title={"Registration Image"}
                selectedFiles={registrationImage}
                setSelectedFiles={setRegistrationImage}
              />
              <ErrorMessage
                name="registration_image"
                component="div"
                className="w-full h-auto text-sm text-red-600 font-extralight max-sm:text-xs"
              />
              <ImageUploader
                title={"Insurance Image"}
                selectedFiles={insuranceImage}
                setSelectedFiles={setInsuranceImage}
              />
              <ErrorMessage
                name="insurance_image"
                component="div"
                className="w-full h-auto text-sm text-red-600 font-extralight max-sm:text-xs"
              />
              <ImageUploader
                title={"Truck Images"}
                selectedFiles={truckImages}
                setSelectedFiles={setTruckImages}
              />
              <ErrorMessage
                name="truck_images"
                component="div"
                className="w-full h-auto text-sm text-red-600 font-extralight max-sm:text-xs"
              />
              <div className="w-full h-auto flex flex-row justify-end items-center gap-1">
                <button
                  type="button"
                  className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-extralight shadow-md max-sm:text-xs max-md:text-sm"
                  onClick={() => setActiveStep("personal-info")}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="w-1/2 h-auto flex flex-row justify-center items-center px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-extralight shadow-md max-sm:text-xs max-md:text-sm"
                >
                  {loading ? (
                    <CircularProgress size={16} sx={{ color: "black" }} />
                  ) : (
                    "Next"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default TruckInfo;
