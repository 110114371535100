import { useEffect, useState } from "react";
import ModalSubmitOffer from "./dialogs/ModalSubmitOffer";
import ModalSubmitNewOffer from "./dialogs/ModalSubmitNewOffer";
import Cookies from "js-cookie";
import axiosConfig from "../../config/axiosConfig";
import AboutMessage from "./AboutMessage";
import MessageBox from "./MessageBox";
import { HiLocationMarker } from "react-icons/hi";
import PopupRejectLoad from "./dialogs/PopupRejectLoad";
import ModalSubmitReport from "./dialogs/ModalSubmitReport";
import { useNavigate } from "react-router-dom";

const LoadDetailCard = ({ loadData, userData, darkMode }) => {
  // console.log('loadData',loadData)
  // console.log("userData",userData)
  const navigate = useNavigate();

  const [showModalSubmitOffer, setShowModalSubmitOffer] = useState(false);
  const [showModalSubmitNewOffer, setShowModalSubmitNewOffer] = useState(false);
  const [showModalSubmitReport, setShowModalSubmitReport] = useState(false);
  const [showBtnsSecOne, setShowBtnsSecOne] = useState(true);
  const [showBtnsSecTwo, setShowBtnsSecTwo] = useState(false);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [messageBoxInfo, setMessageBoxInfo] = useState("");
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [reports, setReports] = useState([]);

  const fetchReports = async () => {
    const token = Cookies.get("token");
    const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
    const endpoint = `/api/v1/user/my-load/reports/${messageBoxInfo[0].offer_load_id}`;
    const axiosInstance = axiosConfig(baseURL, token);
    try {
      const response = await axiosInstance.get(endpoint);
      if (response.status === 200) {
        setReports(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    messageBoxInfo !== "" && fetchReports();
  }, [messageBoxInfo]);

  useEffect(() => {
    const statusOffer = async () => {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = process.env.REACT_APP_GET_OFFER_LOAD_MESSAGES;
      const axiosInstance = axiosConfig(baseURL, token);

      const values = {
        load_id: loadData.id,
      };

      try {
        const response = await axiosInstance.post(endpoint, values);
        if (response.status === 200) {
          if (response.data.data.length === 0) {
            setShowBtnsSecOne(true);
          } else {
            setShowBtnsSecOne(false);
            setShowBtnsSecTwo(true);
            setShowMessageBox(true);
            setMessageBoxInfo(response.data.data);
            const offerLoadId = JSON.stringify(
              response.data.data[0].offer_load_id
            );
            Cookies.set("offerLoadId", offerLoadId, { expires: 1 });
          }
        }
      } catch (error) {
        console.error(error);
        AboutMessage({ status: "error", message: error.response.data.message });
      }
    };
    statusOffer();
  }, [loadData]);

  const rejectLoad = async () => {
    setShowRejectModal(true);
  };

  // const formattedDate = format(
  //   new Date(loadData.load_date),
  //   "yyyy-MM-dd HH:mm:ss"
  // );

  const status = loadData.status;
  const formattedStatus =
    status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

  return (
    <div
      className={`w-full h-auto flex flex-col justify-center items-start gap-4 rounded-md p-4 cursor-default font-extralight text-sm ${
        darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
      }`}
    >
      <p className="cursor-default text-[#a2a6ad] max-md:text-xs">
        Posted on {loadData.load_date}
      </p>
      <div className="w-full h-auto flex flex-col justify-between items-start font-normal max-sm:gap-1">
        <div className="flex flex-row justify-between items-center gap-2">
          <HiLocationMarker className="text-[#a2a6ad] text-lg" />
          <p className="text-base cursor-default max-md:text-sm">
            {loadData.load_pick_up}
          </p>
        </div>
        <div className="flex flex-row justify-start items-center gap-2">
          <HiLocationMarker className="text-[#a2a6ad] text-lg" />
          <p className="text-base cursor-default max-md:text-sm">
            {loadData.load_deliver_to}
          </p>
        </div>
      </div>
      <div className="w-full h-auto flex flex-col justify-start items-start gap-1 font-normal rounded-md p-2">
        <p className="flex flex-row justify-start items-center gap-1 cursor-default max-md:text-sm">
          Status load :
          <span
            className={`${
              loadData.status === "reject" || loadData.status === "missed"
                ? "text-red-600"
                : loadData.status === "accept"
                ? "text-green-600"
                : "text-yellow-500"
            }`}
          >
            {formattedStatus}
          </span>
        </p>
        <p className="cursor-default max-md:text-sm">
          Turck type : {loadData.user_truck_size}
        </p>
        <p className="cursor-default max-md:text-sm">
          Weight : {loadData.load_weight}
        </p>
        <p className="cursor-default max-md:text-sm">
          Load miles : {loadData.load_miles}
        </p>
        <p className="cursor-default max-md:text-sm">
          Location distance : {loadData.location_distance}
        </p>
        <p className="cursor-default max-md:text-sm">
          Price : {loadData.price}
        </p>
        <p className="cursor-default max-md:text-sm">
          Load notes : {loadData.load_notes}
        </p>
      </div>
      {showMessageBox && messageBoxInfo.length > 0 && (
        <MessageBox messageBoxInfo={messageBoxInfo} />
      )}
      {showBtnsSecOne &&
        loadData.status !== "reject" &&
        loadData.status !== "accept" && (
          <div className="w-full h-auto flex flex-row justify-end items-center gap-1">
            <button
              onClick={() => setShowModalSubmitOffer(true)}
              className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-extralight shadow-md max-sm:text-xs max-md:text-sm"
            >
              Send your Offer
            </button>
            <button
              onClick={rejectLoad}
              className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-extralight shadow-md max-sm:text-xs max-md:text-sm"
            >
              Reject
            </button>
          </div>
        )}
      {showBtnsSecTwo &&
        loadData.status !== "reject" &&
        loadData.status !== "accept" && (
          <div className="w-full h-auto flex flex-row justify-center items-center gap-1">
            {loadData.status === "missed" ? (
              <p className="h-auto w-full text-red-600 font-normal p-4">
                This time it was received by someone else.
              </p>
            ) : (
              <>
                <button
                  className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-extralight shadow-md max-sm:text-xs max-md:text-sm"
                  type="button"
                  onClick={() => setShowModalSubmitNewOffer(true)}
                >
                  Send new Offer
                </button>
                <button
                  className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-extralight shadow-md max-sm:text-xs max-md:text-sm"
                  onClick={rejectLoad}
                >
                  Reject
                </button>
              </>
            )}
          </div>
        )}
      {loadData.status === "accept" && (
        <div className="w-full h-auto flex flex-row justify-start items-center gap-1">
          <button
            onClick={() => setShowModalSubmitReport(true)}
            className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-extralight shadow-md max-sm:text-xs max-md:text-sm"
          >
            Send report
          </button>
          {reports.length !== 0 && (
            <button
              onClick={() => navigate("/loads/reports")}
              className="w-1/2 h-auto flex flex-row justify-center items-center gap-1 px-6 py-2 rounded-md bg-[#616161] text-white text-base font-extralight shadow-md max-sm:text-xs max-md:text-sm"
            >
              Show reports
              <span>( {reports.length} )</span>
            </button>
          )}
        </div>
      )}
      {showModalSubmitOffer && (
        <ModalSubmitOffer
          darkMode={darkMode}
          loadData={loadData}
          setShowModalSubmitOffer={setShowModalSubmitOffer}
          setShowBtnsSecOne={setShowBtnsSecOne}
          setShowBtnsSecTwo={setShowBtnsSecTwo}
          setShowMessageBox={setShowMessageBox}
          setMessageBoxInfo={setMessageBoxInfo}
        />
      )}
      {showModalSubmitNewOffer && (
        <ModalSubmitNewOffer
          loadData={loadData}
          setShowModalSubmitNewOffer={setShowModalSubmitNewOffer}
          setMessageBoxInfo={setMessageBoxInfo}
        />
      )}
      {showRejectModal && (
        <PopupRejectLoad
          loadData={loadData}
          userData={userData}
          setShowRejectModal={setShowRejectModal}
        />
      )}
      {showModalSubmitReport && (
        <ModalSubmitReport
          load_id={loadData.id}
          offer_load_id={messageBoxInfo[0].offer_load_id}
          setShowModalSubmitReport={setShowModalSubmitReport}
          fetchReports={fetchReports}
        />
      )}
    </div>
  );
};

export default LoadDetailCard;
