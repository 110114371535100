import { Field, ErrorMessage } from "formik";

const DefaultField = ({ stylefield, type, name, minLength, maxLength, placeholder }) => {
  return (
    <div className="w-full flex flex-col justify-start items-center gap-1 font-extralight">
      <Field
      className={stylefield}
      type={type}
      name={name}
      minLength={minLength}
      maxLength={maxLength}
      placeholder={placeholder}
      />
      <ErrorMessage
      name={name}
      component="div"
      className="w-full h-auto text-sm text-red-600 max-sm:text-xs"
      />
    </div>
  );
};

export default DefaultField;
