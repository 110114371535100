import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import store from "./redux_toolkit/store";
import { Provider } from "react-redux";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Loads from "./pages/loads/index";
import Truck from "./pages/Truck";
import Profile from "./pages/Profile";
import LoadId from "./pages/loads/LoadId";
import Layout from "./components/Layout/Layout";
import axiosConfig from "./config/axiosConfig";
import Cookies from "js-cookie";
import Reports from "./pages/loads/reports";
import ReportId from "./pages/loads/reports/reportId";

const App = () => {
  useEffect(() => {
    let wakeLock = null;

    const requestWakeLock = async () => {
      try {
        wakeLock = await navigator.wakeLock.request("screen");
      } catch (err) {
        console.error(`${err.name}, ${err.message}`);
      }
    };

    const releaseWakeLock = async () => {
      if (wakeLock !== null) {
        await wakeLock.release();
        wakeLock = null;
      }
    };

    requestWakeLock();

    return () => {
      releaseWakeLock();
    };
  }, []);

  useEffect(() => {
    const fetchFirebaseConfig = async () => {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = process.env.REACT_APP_GET_FIREBASE_CONFIG;
      const axiosInstance = axiosConfig(baseURL, token);
      try {
        const response = await axiosInstance.get(endpoint);
        // console.log(response.data.data)
        const firebaseConfig = JSON.stringify(response.data.data);
        Cookies.set("firebaseConfig", firebaseConfig, {
          expires: 1,
          sameSite: "None",
        });
      } catch (error) {
        console.error(error);
      }
    };
    fetchFirebaseConfig();
  }, []);

  return (
    <Provider store={store}>
      <Layout>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="/" element={<Home />} />
          <Route path="truck" element={<Truck />} />
          <Route path="profile" element={<Profile />} />
          <Route path="/loads" element={<Loads />} />
          <Route path="/load/:id" element={<LoadId />} />
          <Route path="/loads/reports" element={<Reports />} />
          <Route path="/loads/report/:id" element={<ReportId />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </Provider>
  );
};

export default App;
