// import { IoMdPerson } from "react-icons/io";
// import { RiCustomerServiceFill } from "react-icons/ri";

const MessageBox = ({ messageBoxInfo }) => {
  return (
    <div className="w-full h-auto flex flex-col justify-start items-end gap-2 bg-chatbg rounded-md p-2 overflow-auto">
      {messageBoxInfo.map((message) => (
        <div
          className={`w-auto h-auto flex flex-row justify-start items-center ${
            message.sender === "admin"
              ? " text-black ml-auto"
              : " text-black mr-auto"
          }`}
          key={message.id}
        >
          <div>
            {message.sender === "admin" ? (
              <div className="flex flex-row justify-center items-start gap-2  bg-[#616161] text-white p-3 rounded-l-xl rounded-br-xl shadow-md">
                <p className="flex flex-row justify-center items-center flex-wrap font-normal max-md:text-xs">
                  {message.message}
                  <br />
                  {"Our price is " + message.price}
                </p>
                {/* <div className="bg-[#F1F5F9] rounded-full shadow-md p-1 border-2 border-black">
                  <RiCustomerServiceFill className="text-base" />
                </div> */}
              </div>
            ) : (
              <div className="flex flex-row justify-center items-center gap-2 bg-gradient-to-l from-cyan-500 to-blue-500 text-white p-3  rounded-r-xl rounded-bl-xl shadow-md">
                {/* <div className="bg-[#F1F5F9] rounded-full shadow-md p-1 border-2 border-black">
                  <IoMdPerson className="text-base" />
                </div> */}
                <p className="flex flex-row justify-center items-center flex-wrap font-normal max-md:text-xs">
                  {message.message}
                  <br />
                  {"Our price is " + message.price}
                  <br />
                  {message.updated_at}
                </p>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MessageBox;
