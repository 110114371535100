import Swal from "sweetalert2";
import Cookies from "js-cookie";
import axiosConfig from "../../../config/axiosConfig";
import AboutMessage from "../AboutMessage";

const PopupRejectLoad = ({
  userData,
  loadData,
  setShowRejectModal,
}) => {
  Swal.fire({
    title: "",
    text: "Are you sure?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#1976D2",
    cancelButtonColor: "#dc2626",
    confirmButtonText: "Yes, reject it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const token = Cookies.get("token");
        const baseURL = process.env.REACT_APP_LOADAL_BASE_URL;
        const endpoint = process.env.REACT_APP_REJECT_LOAD_URL;
        const axiosInstance = axiosConfig(baseURL, token);

        const values = {
          user_id: userData.id,
          load_id: loadData.id,
        };

        const response = await axiosInstance.post(endpoint, values);

        if (response.status === 200) {
          AboutMessage({ status: "success", message: response.data.message });
          setShowRejectModal(false);
          window.location.reload();
        }
      } catch (error) {
        if (error.response) {
          AboutMessage({
            status: "error",
            message: error.response.data.message,
          });
        } else {
          console.log(error.message);
        }
      }
    } else {
      setShowRejectModal(false);
    }
  });
};

export default PopupRejectLoad;
