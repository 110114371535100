import { IoIosArrowForward } from "react-icons/io";
import dataCards from "../../data/dataCard";
import { useSelector } from "react-redux";

const HomeDetails = () => {
  const darkMode = useSelector((state) => state.darkMode);

  return (
    <div
      className={`w-full h-full flex flex-col justify-start items-center font-extralight px-4 ${
        darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
      }`}
    >
      <div
        className={`w-full h-auto flex flex-col justify-center items-start gap-6 rounded-md p-8 max-md:p-6 max-sm:p-4 max-md:gap-2 ${
          darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
        }`}
      >
        <h1 className="w-full h-full text-xl font-normal uppercase max-sm:text-xl">Book vehicle for</h1>
        <div className="w-full h-auto flex flex-row justify-center items-center flex-wrap gap-0 max-md:py-5">
          {dataCards.map((index) => {
            return (
              <div
                key={index.txt}
                className={`w-4/12 h-auto flex flex-col justify-center items-center gap-4 rounded-md p-5 cursor-pointer shadow-md max-md:w-6/12 max-md:min-h-36 max-md:shadow-none ${
                  darkMode ? "bg-gray-900 max-md:border-2 border-[#1E293B]" : "bg-[#F1F5F9] max-md:border-2 border-[#E2E8F0]"
                }`}
              >
                <div className="w-full h-auto ">{index.icon}</div>
                <div className="w-full h-auto flex flex-row justify-between items-start">
                  <p className="cursor-default text-base max-md:text-sm max-sm:text-xs">
                    {index.txt}
                  </p>
                  <IoIosArrowForward className="max-sm:text-xs max-sm:relative top-[2px]" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HomeDetails;
