import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axiosConfig from "../../config/axiosConfig";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import Map from "../../components/module/Map";
import LoadDetailCard from "../../components/module/LoadDetailCard";
import Spinner from "../../components/module/Spinner";
import MobileMenu from "../../components/module/MobileMenu";
import { useSelector } from "react-redux";

const LoadId = () => {
  let { id } = useParams();
  const darkMode = useSelector((state) => state.darkMode);
  const [loadData, setLoadData] = useState("");
  const [userData, setUserData] = useState("");
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchLoadData = async () => {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_LOADAL_BASE_URL;
      const endpoint = process.env.REACT_APP_GET_LOADAL_BY_ID_URL;
      const axiosInstance = axiosConfig(baseURL, token);

      try {
        const response = await axiosInstance.get(endpoint + id);
        setLoadData(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchUserData = async () => {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = process.env.REACT_APP_PERSONAL_INFO_URL;
      const axiosInstance = axiosConfig(baseURL, token);

      try {
        const response = await axiosInstance.get(endpoint);
        setUserData(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchData = async () => {
      setLoading(true);
      await Promise.all([fetchLoadData(), fetchUserData()]);
      setLoading(false);
    };

    fetchData();
  }, [id]);

  if (loading) {
    return (
      <div
        className={`h-screen w-full flex flex-col justify-center items-center ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Load</title>
      </Helmet>
      <div
        className={`w-full h-screen p-4 overflow-auto ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <div className="w-full h-auto flex flex-col justify-start items-center gap-2 pb-20 overflow-auto">
          <div className="w-full h-40 flex flex-row justify-center items-center shadow-md cursor-pointer">
            <Map
              loadData={loadData}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />
          </div>
          <LoadDetailCard
            loadData={loadData}
            userData={userData}
            darkMode={darkMode}
          />
        </div>
        <div className="fixed bottom-0 left-0 right-0">
          <MobileMenu />
        </div>
        {isModalOpen && (
          <div className="fixed inset-0 flex justify-center items-center">
            <Map
              loadData={loadData}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default LoadId;
