import { NavLink } from "react-router-dom";
import { FaTruck, FaTruckLoading, FaHome, FaUser } from "react-icons/fa";

const DesktopNavber = () => {
  return (
    <nav className="w-screen h-16 flex flex-row justify-center items-center bg-amber-400 p-5 text-base max-md:hidden">
      <div className="w-full h-full flex flex-row items-center justify-start gap-8 font-normal uppercase">
        <NavLink
          to={"/"}
          className="flex flex-row justify-center items-center gap-1"
        >
          <FaHome className="text-[1.20rem] relative bottom-[1px]" />
          Home
        </NavLink>
        <NavLink
          to={"/loads"}
          className="flex flex-row justify-center items-center gap-1"
        >
          <FaTruckLoading className="text-[1.25rem]" />
          Loads
        </NavLink>
        <NavLink
          to={"/truck"}
          className="flex flex-row justify-center items-center gap-1"
        >
          <FaTruck className="text-[1.25rem] " />
          Truck
        </NavLink>
        <NavLink
          to={"/profile"}
          className="flex flex-row justify-center items-center gap-1"
        >
          <FaUser className="text-[1.05rem]" />
          Profile
        </NavLink>
      </div>
    </nav>
  );
};

export default DesktopNavber;
