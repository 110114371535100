import Cookies from "js-cookie";
import { useState } from "react";
import axiosConfig from "../../../config/axiosConfig";
import AboutMessage from "../AboutMessage";
import styles from "../../../styles/styles.module.css";
// import { IoIosClose } from "react-icons/io";
// import { FaCircle, FaRegDotCircle } from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";
import { BsCurrencyDollar } from "react-icons/bs";

const ModalSubmitNewOffer = ({
  loadData,
  setShowModalSubmitNewOffer,
  setMessageBoxInfo,
}) => {
  const [offerPrice, setOfferPrice] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const values = {
      load_id: loadData.id,
      price: offerPrice,
      message: description,
    };

    if (offerPrice === "") {
      AboutMessage({ status: "error", message: "Please enter the price" });
      return;
    }

    if (offerPrice.length > 8) {
      AboutMessage({
        status: "error",
        message: "The offer price field must not be greater than 8 characters",
      });
      return;
    }

    if (description === "") {
      AboutMessage({
        status: "error",
        message: "Please enter the description",
      });
      return;
    }

    if (description.length > 1500) {
      AboutMessage({
        status: "error",
        message: "The field must not be greater than 1500 characters",
      });
      return;
    }

    try {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = process.env.REACT_APP_SEND_NEW_OFFER;
      const axiosInstance = axiosConfig(baseURL, token);

      const response = await axiosInstance.post(endpoint, values);

      if (response.status === 200) {
        AboutMessage({ status: "success", message: response.data.message });

        const fetchMessagesEndpoint = "/api/v1/user/get-offer-load/messages";
        const fetchMessagesResponse = await axiosInstance.post(
          fetchMessagesEndpoint,
          { load_id: loadData.id }
        );

        setMessageBoxInfo(fetchMessagesResponse.data.data);
        setShowModalSubmitNewOffer(false);
        setOfferPrice("");
        setDescription("");
      }
    } catch (error) {
      if (error.response) {
        setShowModalSubmitNewOffer(false);
        setOfferPrice("");
        setDescription("");
        AboutMessage({
          status: "error",
          message: error.response.data.message,
        });
      } else {
        console.error(error.message);
      }
    }
  };

  const cancel = () => {
    setShowModalSubmitNewOffer(false);
    setOfferPrice("");
    setDescription("");
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-60">
      <div className="modal font-extralight">
        <div className="modal-content">
          {/* <span
            className="close"
            onClick={() => setShowModalSubmitNewOffer(false)}
          >
            <IoIosClose />
          </span> */}
          <div className="w-full h-auto flex flex-col justify-between items-start gap-5">
          <p className="text-black text-base font-normal pb-2">Send New Offer</p>
            <div className="w-full h-auto flex flex-col justify-between items-start gap-3">
              <div className="w-full h-auto flex flex-col justify-between items-start max-sm:gap-1">
                <div className="flex flex-row justify-between items-center gap-2">
                  <HiLocationMarker className="text-green-600" />
                  <p className="text-base cursor-default text-black max-md:text-sm">
                    {loadData.load_pick_up}
                  </p>
                </div>
                <div className="flex flex-row justify-start items-center gap-2">
                  <HiLocationMarker className="text-red-600" />
                  <p className="text-base cursor-default text-black max-md:text-sm">
                    {loadData.load_deliver_to}
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start gap-1 text-black">
                <p className="cursor-default max-md:text-xs">
                  Turck type : {loadData.user_truck_size}
                </p>
                <p className="cursor-default max-md:text-xs">
                  Weight : {loadData.load_weight}
                </p>
                <p className="cursor-default max-md:text-xs">
                  Load miles : {loadData.load_miles}
                </p>
                <p className="cursor-default max-md:text-xs">
                  Location distance : {loadData.location_distance}
                </p>
                <p className="flex flex-row justify-start items-center cursor-default max-md:text-xs">
                  Price : <BsCurrencyDollar /> {loadData.price}
                </p>
                <p className="cursor-default max-md:text-xs">
                  Load notes : {loadData.load_notes}
                </p>
              </div>
            </div>
            <div className="w-full h-auto flex flex-col justify-center items-start gap-2">
              <input
                className={styles.stylefield}
                value={offerPrice}
                onChange={(e) => {
                  const validatedValue = e.target.value.replace(/\D/g, "");
                  setOfferPrice(validatedValue);
                }}
                placeholder="Offer price"
                pattern="[0-9]*"
              />
              <textarea
                className="w-full h-20 text-black bg-[#F1F5F9] p-3 rounded-md text-base outline-none max-sm:text-xs"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
              ></textarea>
            </div>
            <div className="w-full h-auto flex flex-row justify-center items-center gap-1">
              <button
                className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                type="submit"
                onClick={handleSubmit}
              >
                Send
              </button>
              <button
                className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                type="button"
                onClick={cancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSubmitNewOffer;
