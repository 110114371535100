import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../module/Spinner";
import { GoInbox } from "react-icons/go";
import ReportCard from "../module/ReportCard";

const ListReportsCards = ({ data }) => {
  const darkMode = useSelector((state) => state.darkMode);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data.length !== 0) {
      setLoading(false);
    }
  }, [data]);

  return (
    <div className="w-full h-full flex flex-row justify-center items-center flex-wrap gap-3 px-4">
      {loading === true ? (
        <div
          className={`h-screen w-full flex flex-col justify-start items-center pt-52 ${
            darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
          }`}
        >
          <Spinner />
        </div>
      ) : data.length === 0 ? (
        <div
          className={`w-full h-screen flex flex-row justify-center items-start ${
            darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
          }`}
        >
          <div
            className={`w-full h-auto flex flex-col justify-center items-center gap-2 rounded-md p-4 ${
              darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
            }`}
          >
            <GoInbox className="text-3xl" />
            <p className="cursor-default font-extralight text-base max-md:text-sm max-sm:text-xs">
              There are no reports
            </p>
          </div>
        </div>
      ) : (
        data.map((index) => <ReportCard key={index.id} {...index} />)
      )}
    </div>
  );
};

export default ListReportsCards;
