import { useEffect, useState } from "react";
import Spinner from "../module/Spinner";

const ReportDetail = ({ data, darkMode }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data !== "") {
      setLoading(false);
      // console.log(data)
    }
  }, [data]);

  if (loading) {
    return (
      <div
        className={`h-auto w-full flex flex-col justify-start items-center pt-52 ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className={`w-full h-auto flex flex-col justify-center items-start gap-4 rounded-md p-4 cursor-default font-extralight text-sm ${
        darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
      }`}
    >
      <div className="w-full h-auto flex flex-row justify-between items-center ">
        <p className="cursor-default text-[#a2a6ad] max-md:text-sm">
          Posted on {data.created_at}
        </p>
      </div>
      <div className="flex flex-col justify-start items-start gap-1 font-normal">
        <div className="flex flex-row justify-start items-center gap-1">
          <span>Full Name :</span>
          <p>
            <span>{data.first_name}</span>
            <span>{data.last_name}</span>
          </p>
        </div>
        <p>
          <span>Description : </span>
          {data.description}
        </p>
        <p>
          <span>citizenship : </span>
          {data.citizenship}
        </p>
        <p>
          <span>phone : </span>
          {data.phone}
        </p>
        <p>
          <span>Report Type : </span>
          {data.report_type}
        </p>
        <p>
          <span>location : </span>
          {data.location}
        </p>
        <div className="w-full h-auto flex flex-col justify-center items-start gap-1 flex-wrap pt-2">
          <span>Images : </span>
          <div className="w-full h-auto flex flex-row justify-start items-center gap-1 flex-wrap pt-2">
            {data.galleries.map((index) => {
              return (
                <img
                  className="w-5/12 h-auto rounded-md"
                  key={index.id}
                  src={`https://atoz-register.metariom.shop/${index?.image}`}
                  alt={index.name}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportDetail;
