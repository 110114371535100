import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { setDarkMode } from "../../redux_toolkit/features/darkModeSlice";
import { useNavigate } from "react-router-dom";

const ProfileDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.darkMode);

  const toggleDarkMode = (e) => {
    dispatch(setDarkMode(e.target.checked));
    localStorage.setItem("darkMode", e.target.checked);
  };

  const logout = () => {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    navigate("/login");
  };

  return (
    <div className="w-full h-auto px-4 flex flex-col justify-start items-center">
      <div
        className={`w-full h-full flex flex-col justify-center items-start gap-10 font-extralight rounded-md p-6 ${
          darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
        }`}
      >
        <h1 className="text-xl font-normal uppercase max-sm:text-xl">
          App Setting
        </h1>
        <div className="flex flex-col gap-6">
          <div className="flex flex-row justify-start items-center gap-4">
            <p className="text-base">Dark theme</p>
            <Switch
              className="relative top-[2px]"
              checked={darkMode}
              onChange={toggleDarkMode}
              inputProps={{ "aria-label": "controlled" }}
              size="small"
            />
          </div>
          <button
            type="submit"
            className="w-full h-auto flex flex-row justify-center items-center px-6 py-2 rounded-md border-2 border-red-600 text-red-600 text-base font-bold shadow-md max-md:text-sm"
            onClick={logout}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
