import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useEffect, useState } from "react";

const steps = [
  "Personal Info",
  "Truck Info",
  "Driver Licence",
  "Bank Info",
  "Phone Verify",
  "Sign Contract",
];

const CustomStepper = ({ activeStep }) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  useEffect(() => {
    if (activeStep === "personal-info") {
      setActiveStepIndex(1);
    } else if(activeStep === "truck-info"){
      setActiveStepIndex(2);
    } else if(activeStep === "driver-licence"){
      setActiveStepIndex(3);
    } else if(activeStep === "bank-info"){
      setActiveStepIndex(4);
    } else if(activeStep === "phone-verify"){
      setActiveStepIndex(5);
    } else if(activeStep === "sign-contract"){
      setActiveStepIndex(6);
    }
  },[activeStep])

  return (
    <Box sx={{ width: "100%" }} className="bg-[#F1F5F9] text-2xl pt-12">
      <Stepper activeStep={activeStepIndex} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default CustomStepper;
