import Cookies from "js-cookie";

const getLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.watchPosition(
      (position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;

        const location = {
          lat,
          lng,
        };

        const locationJSON = JSON.stringify(location);
        Cookies.set("location", locationJSON);
      },
      (error) => {
        console.error(error);
      }
    );
  } else {
    console.log("Geolocation is not supported by this browser");
  }
};

export default getLocation;
