import { Formik, Form } from "formik";
import DefaultField from "../../module/DefaultField";
import PasswordField from "../../module/PasswordField";
import axiosConfig from "../../../config/axiosConfig";
import AboutMessage from "../../module/AboutMessage";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const LoginForm = () => {
  const navigate = useNavigate();
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validate={(values) => {
        const errors = {};

        if (!values.email) {
          errors.email = "Please enter your email";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = "The email address is invalid";
        }

        if (!values.password) {
          errors.password = "Please enter your password";
        } 
        else if (!/^[A-Za-z0-9._%+-=()&?!#$`^"'{}]+$/.test(values.password)) {
          errors.password =
            "The password must be a combination of upper and lower case letters and numbers";
        }

        return errors;
      }}
      onSubmit={async (values) => {
        const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
        const endpoint = process.env.REACT_APP_LOGIN_URL;
        const axiosInstance = axiosConfig(baseURL);

        try {
          const response = await axiosInstance.post(endpoint, values);
          const token = response.data.token;
          Cookies.set("token", token, { expires: 1 });
          localStorage.setItem("email", values.email);
          navigate("/register");
        } catch (error) {
          if (error.response) {
            AboutMessage({
              status: "error",
              message: error.response.data.message,
            });
          } else {
            console.log(error.message);
          }
        }
      }}
    >
      {() => (
        <Form className="w-6/12 h-auto flex flex-col gap-2 font-medium max-sm:w-10/12 max-md:w-8/12">
          <DefaultField
            stylefield="w-full flex flex-row justify-start items-center rounded-md p-3 text-white text-base outline-none bg-[#111827a6] placeholder:text-white max-sm:text-xs"
            type="email"
            name="email"
            minLength="0"
            maxLength="255"
            placeholder="Email"
          />
          <PasswordField
            name="password"
            minLength="6"
            maxLength="255"
            placeholder="Password"
          />
          <button
            type="button"
            className="flex flex-row justify-start items-center gap-1 font-extralight text-base text-white max-md:text-sm max-sm:text-xs"
            onClick={() =>
              AboutMessage({
                status: "info",
                message: "This section will be activated soon",
              })
            }
          >
            <span className="text-amber-400">Forget Password ?</span>
          </button>
          <div className="w-full h-auto flex flex-col gap-2 text-base pt-10 text-white font-medium max-md:font-extralight">
            <button
              type="submit"
              className="w-full h-auto flex flex-row justify-center items-center gap-1 bg-gradient-to-l from-amber-500 to-amber-400 rounded-md p-2 max-sm:text-xs"
            >
              Login
            </button>
            <button
              type="button"
              className="w-full h-auto rounded-[4px] p-2 max-sm:text-xs max-md:text-sm"
              id="register_btn"
              onClick={() => navigate("/register")}
            >
              Regesteration
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
