import { useRef } from "react";
import { BsCloudUpload } from "react-icons/bs";

const ImageUploader = ({ title, selectedFiles, setSelectedFiles }) => {
  // console.log(selectedFiles)
  const inputRef = useRef(null);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleDelete = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <div className="flex flex-col justify-center items-start gap-4 bg-[#F1F5F9] font-extralight rounded-md p-4">
      <p className="text-black cursor-default max-sm:text-xs max-md:text-sm">{title}</p>
      <div className="w-full h-auto flex flex-row justify-start items-center">
        <button
          className="w-full h-auto flex flex-row justify-center items-center gap-2 px-6 py-2 rounded-md bg-[#E2E8F0] text-black text-base font-extralight shadow-md max-sm:text-xs max-md:text-sm"
          type="button"
          onClick={handleClick}
        >
          <BsCloudUpload className="relative top-[2px] text-xl max-sm:text-gl" />
          Choose a file to upload
        </button>
        <input
          ref={inputRef}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: "none" }}
          multiple
        />
      </div>
      {selectedFiles.length > 0 && (
        <div className="w-full h-auto flex flex-col justify-start items-center gap-2">
          {selectedFiles.map((file, index) => (
            <div
              key={index}
              className="w-full h-full flex flex-row justify-center items-start gap-2 bg-[#E2E8F0] rounded-md p-2 shadow-md max-sm:flex-col"
            >
              <img
                src={file.url ? file.url : URL.createObjectURL(file)}
                alt={file.name}
                className="w-7/12 h-auto rounded-md max-sm:w-full"
              />
              <div className="w-5/12 h-full flex flex-col justify-center items-start">
                <div className="w-full h-auto flex flex-row justify-start items-center cursor-default text-black max-md:text-xs">
                  {file.name}
                </div>
                <button
                  type="button"
                  onClick={() => handleDelete(index)}
                  className="text-red-500 max-md:text-sm"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
