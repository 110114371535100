import DefaultField from "../../module/DefaultField";
import { Formik, Form } from "formik";
import { Checkbox, CircularProgress } from "@mui/material";
import styles from "../../../styles/styles.module.css";
import ImageUploader from "../../module/ImageUploader";
import { useState } from "react";
import AboutMessage from "../../module/AboutMessage";
import Cookies from "js-cookie";
import axiosConfig from "../../../config/axiosConfig";

const BankInfoForm = ({ setActiveStep }) => {
  const [bankConfirmationCheck, setBankConfirmationCheck] = useState([]);
  const [bankConfirmationLetter, setBankConfirmationLetter] = useState([]);
  const [quickPay, setQuickPay] = useState("0");
  const [loading, setLoading] = useState(false);

  return (
    <div className="w-full h-screen flex flex-col justify-start items-center bg-[#F1F5F9] text-black font-extralight pt-10">
      <div className="w-11/12 h-auto flex flex-col justify-center items-start gap-10 bg-[#E2E8F0] rounded-md p-8">
        <div className="flex flex-row justify-center items-end gap-2">
          <h1 className="text-xl uppercase max-sm:text-sm">Register</h1>
          <p className="text-xl text-[#1976D2] uppercase max-sm:text-sm">
            Bank Info
          </p>
        </div>
        <Formik
          initialValues={{
          name: "",
          account_number: "",
          account_number_confirm: "",
          routing_number: "",
          routing_number_confirm: "",
          quick_pay: "",
          bank_confirmation_check: "",
          bank_confirmation_letter: "",
          }}
           validate={(values) => {
             const errors = {};

            if (!values.name) {
              errors.name = "Please enter bank account holder name";
            } else if (!/^[A-Za-z\s]+$/.test(values.name)) {
              errors.name = "Should only contain Latin letters a - z - A - Z";
            }
            if (!values.account_number) {
              errors.account_number = "Please enter account number";
            } else if (!/^[0-9]+$/.test(values.account_number)) {
              errors.account_number = "Should only contain numbers 0 - 9";
            }
            if (!values.account_number_confirm) {
              errors.account_number_confirm = "Please enter account number confirm";
            } else if (!/^[0-9]+$/.test(values.account_number_confirm)) {
              errors.account_number_confirm = "Should only contain numbers 0 - 9";
            }
            if(values.account_number !== values.account_number_confirm) {
              errors.account_number = "Do not match";
              errors.account_number_confirm = "Do not match";
            }
            if (!values.routing_number) {
              errors.routing_number = "Please enter routing number";
            } else if (!/^[0-9]+$/.test(values.routing_number)) {
              errors.routing_number = "Should only contain numbers 0 - 9";
            }
            if (!values.routing_number_confirm) {
              errors.routing_number_confirm = "Please enter routing number confirm";
            } else if (!/^[0-9]+$/.test(values.routing_number_confirm)) {
              errors.routing_number_confirm = "Should only contain numbers 0 - 9";
            }
            if(values.routing_number !== values.routing_number_confirm) {
              errors.routing_number = "Do not match";
              errors.routing_number_confirm = "Do not match";
            }

             return errors;
           }}
           onSubmit={async (values) => {
            try {
              values.quick_pay = quickPay;
              values.bank_confirmation_check = bankConfirmationCheck;
              values.bank_confirmation_letter = bankConfirmationLetter;
          
              if (values.bank_confirmation_check.length === 0 && values.bank_confirmation_letter.length === 0) {
                AboutMessage({ status: 'error',message: "Please upload file" });
                return;
              }
              setLoading(true);
              const token = Cookies.get('token');
              const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
              const endpoint = process.env.REACT_APP_BANK_INFO_URL;
              const axiosInstance = axiosConfig(baseURL, token);
          
              const config = {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              };
          
              const response = await axiosInstance.post(endpoint, values, config);
              if (response.status === 200) {
                setActiveStep("phone-verify");
              }
            } catch (error) {
              console.error(error);
              if (error.response) {
                const errorsObject = error.response.data.message;
                Object.entries(errorsObject).forEach(([key, errorMessages]) => {
                  errorMessages.forEach((errorMessage) => {
                    AboutMessage({ status: 'error',message: errorMessage });
                  });
                });
              } else {
                console.log(error.message);
              }
            } finally {
              setLoading(false);
            }
          }}
          >          
          {() => (
            <Form className="w-full h-auto flex flex-col gap-2 font-medium">
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="name"
                minLength="0"
                maxLength="255"
                placeholder="Bank Account Holder Name"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="account_number"
                minLength="0"
                maxLength="255"
                placeholder="Account Number"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="account_number_confirm"
                minLength="0"
                maxLength="255"
                placeholder="Account Number Confirm"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="routing_number"
                minLength="0"
                maxLength="255"
                placeholder="Routing Number"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="routing_number_confirm"
                minLength="0"
                maxLength="255"
                placeholder="routingNumberConfirm"
              />
              <div className="flex flex-row justify-start items-center text-white gap-1">
                <Checkbox
                  value={quickPay}
                  onChange={() => setQuickPay(quickPay === "0" ? "1" : "0")}
                  sx={{ padding: "0.1rem" }}
                  size="small"
                />
                <p className="cursor-default font-extralight text-base text-black max-sm:text-xs">
                  Default to Quick Pay
                </p>
              </div>
              <ImageUploader
              title={'Bank Confirmation Check'}
              selectedFiles={bankConfirmationCheck}
              setSelectedFiles={setBankConfirmationCheck}
              />
              <ImageUploader
              title={'Bank Confirmation Letter'}
              selectedFiles={bankConfirmationLetter}
              setSelectedFiles={setBankConfirmationLetter}
              />
              <div className="w-full h-auto flex flex-row justify-end items-center gap-1">
                <button
                type="button"
                className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-extralight shadow-md max-sm:text-xs max-md:text-sm"
                onClick={() => setActiveStep('driver-licence')}
                >
                  Back
                </button>
                <button
                type="submit"
                className="w-1/2 h-auto flex flex-row justify-center items-center px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-extralight shadow-md max-sm:text-xs max-md:text-sm"
                >
                  {loading ? (
                    <CircularProgress size={16} sx={{ color: "black" }} />
                  ) : (
                    "Next"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default BankInfoForm;
