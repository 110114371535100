import { useState, useEffect } from "react";
import styles from "../../../styles/styles.module.css";
import DefaultField from "../../module/DefaultField";
import { Formik, Form, ErrorMessage } from "formik";
import Checkbox from "@mui/material/Checkbox";
import SelectCountryCode from "../../module/select/SelectCountryCode";
import axiosConfig from "../../../config/axiosConfig";
import Cookies from "js-cookie";
import AboutMessage from "../../module/AboutMessage";
import CircularProgress from "@mui/material/CircularProgress";

const PersonalInfoForm = ({ setActiveStep }) => {
  const [company, setCompany] = useState(false);
  const [countryCode, setCountryCode] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = process.env.REACT_APP_GET_COUNTRY_CODE_URL;
      const axiosInstance = axiosConfig(baseURL, token);

      try {
        const response = await axiosInstance.get(endpoint);
        response.status === 200 && setCountryCode(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const token = Cookies.get("token");
  //     const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
  //     // const endpoint = process.env.REACT_APP_GET_COUNTRY_CODE_URL;
  //     const endpoint = "/api/v1/register/personal-info"
  //     const axiosInstance = axiosConfig(baseURL, token);

  //     try {
  //       const response = await axiosInstance.get(endpoint);
  //       console.log(response.data.data)

  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  return (
    <div className="w-full h-screen flex flex-col justify-start items-center bg-[#F1F5F9] text-black font-extralight pt-10">
      <div className="w-11/12 h-auto flex flex-col justify-center items-start gap-10 bg-[#E2E8F0] rounded-md p-8">
        <div className="flex flex-row justify-center items-end gap-2">
          <h1 className="text-xl uppercase cursor-default max-sm:text-sm">
            Register
          </h1>
          <p className="text-xl text-[#1976D2] cursor-default uppercase max-sm:text-sm">
            Personal Info
          </p>
        </div>
        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            country_code: "",
            phone: "",
            backup_number: "",
            company: company,
            citizenship_ssn: "",
            company_name: "",
            citizenship_ein: "",
            mc_number: "",
          }}
          validate={(values) => {
            const errors = {};

            if (!values.first_name) {
              errors.first_name = "Please enter your first name";
            } else if (!/^[A-Za-z\s]+$/.test(values.first_name)) {
              errors.first_name =
                "Should only contain Latin letters a - z - A - Z";
            }
            if (!values.last_name) {
              errors.last_name = "Please enter your last name";
            } else if (!/^[A-Za-z\s]+$/.test(values.last_name)) {
              errors.last_name =
                "Should only contain Latin letters a - z - A - Z";
            }
            if (!values.country_code) {
              errors.country_code = "Please select your country code";
            }
            if (!values.phone) {
              errors.phone = "Please enter your phone";
            } else if (!/^[0-9]+$/.test(values.phone)) {
              errors.phone = "should only contain numbers 0 - 9";
            }
            if (!values.backup_number) {
              errors.backup_number = "Please enter your backup phone";
            } else if (!/^[0-9]+$/.test(values.backup_number)) {
              errors.backup_number = "should only contain numbers 0 - 9";
            }
            if (values.phone === values.backup_number) {
              errors.phone = "Do not match";
              errors.backup_number = "Do not match";
            }
            if (!values.citizenship_ssn) {
              errors.citizenship_ssn = "Please enter your citizenship ssn";
            } else if (!/^[A-Za-z0-9]+$/.test(values.citizenship_ssn)) {
              errors.citizenship_ssn =
                "should only contain Latin letters a - z - A - Z - 0 - 9";
            }

            if (company === true) {
              if (!values.company_name) {
                errors.company_name = "Please enter your company name";
              } else if (!/^[A-Za-z\s]+$/.test(values.company_name)) {
                errors.company_name =
                  "should only contain Latin letters a - z - A - Z";
              }
              if (!values.citizenship_ein) {
                errors.citizenship_ein = "Please enter your citizenship ein";
              } else if (!/^[A-Za-z\s]+$/.test(values.citizenship_ein)) {
                errors.citizenship_ein =
                  "should only contain Latin letters a - z - A - Z";
              }
              if (!values.mc_number) {
                errors.mc_number = "Please enter your mc number";
              } else if (!/^[0-9]+$/.test(values.mc_number)) {
                errors.mc_number = "should only contain numbers 0 - 9";
              }
            }

            return errors;
          }}
          onSubmit={async (values) => {
            try {
              setLoading(true);
              const token = Cookies.get("token");
              const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
              const endpoint = process.env.REACT_APP_PERSONAL_INFO_STORE_URL;
              const axiosInstance = axiosConfig(baseURL, token);

              if (company === false) {
                const updatedValues = {
                  ...values,
                  company: "no",
                  company_name: "",
                  citizenship_ein: "",
                  mc_number: "",
                };

                const response = await axiosInstance.post(
                  endpoint,
                  updatedValues
                );
                response.status === 200 && setActiveStep("truck-info");
              } else {
                const updatedValues = {
                  ...values,
                  company: "yes",
                  citizenship_ssn: "",
                };

                const response = await axiosInstance.post(
                  endpoint,
                  updatedValues
                );
                if (response.status === 200) {
                  setActiveStep("truck-info");
                }
              }
            } catch (error) {
              if (error.response) {
                const errorsObject = error.response.data.message;
                Object.entries(errorsObject).forEach(([key, errorMessages]) => {
                  errorMessages.forEach((errorMessage) => {
                    AboutMessage({ status: 'error', message: errorMessage });
                  });
                });
              }
            } finally {
              setLoading(false);
            }
          }}
        >
          {({ setFieldValue }) => (
            <Form className="w-full h-auto flex flex-col gap-2 font-medium">
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="first_name"
                minLength="2"
                maxLength="255"
                placeholder="First Name"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="last_name"
                minLength="2"
                maxLength="255"
                placeholder="Last Name"
              />
              <SelectCountryCode
                title="Country Code"
                name="country_code"
                data={countryCode}
                onChange={(selectedValue) =>
                  setFieldValue("country_code", selectedValue)
                }
              />
              <ErrorMessage
                name="country_code"
                component="div"
                className="w-full h-auto text-sm text-red-600 font-extralight max-sm:text-xs"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="phone"
                minLength="2"
                maxLength="255"
                placeholder="Phone"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="backup_number"
                minLength="2"
                maxLength="255"
                placeholder="Backup Phone"
              />
              {!company && (
                <DefaultField
                  stylefield={styles.stylefield}
                  type="text"
                  name="citizenship_ssn"
                  minLength="2"
                  maxLength="255"
                  placeholder="Citizenship Ssn"
                />
              )}
              <div className="flex flex-row justify-start items-center text-white gap-1">
                <Checkbox
                  checked={company}
                  onChange={() => setCompany(!company)}
                  sx={{ padding: "0.1rem" }}
                  size="small"
                />
                <p className="cursor-default font-extralight text-base text-black max-sm:text-xs">
                  Company
                </p>
              </div>
              {company && (
                <div className="w-full h-auto flex flex-col gap-2">
                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="company_name"
                    minLength="2"
                    maxLength="255"
                    placeholder="Company Name"
                  />
                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="citizenship_ein"
                    minLength="2"
                    maxLength="255"
                    placeholder="Citizenship Ein"
                  />
                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="mc_number"
                    minLength="0"
                    maxLength="255"
                    placeholder="MC Number"
                  />
                </div>
              )}
              <div className="w-full h-auto flex flex-row justify-end items-center">
                <button
                  type="submit"
                  className="w-1/2 h-auto flex flex-row justify-center items-center px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-extralight shadow-md max-sm:text-xs max-md:text-sm"
                >
                  {loading ? (
                    <CircularProgress size={16} sx={{ color: "black" }} />
                  ) : (
                    "Next"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PersonalInfoForm;