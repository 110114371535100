import { useState } from "react";
import DefaultField from "../../module/DefaultField";
import { Formik, Form, ErrorMessage } from "formik";
import MaskedInput from "react-maskedinput";
import styles from "../../../styles/styles.module.css";
import ImageUploader from "../../module/ImageUploader";
import AboutMessage from "../../module/AboutMessage";
import axiosConfig from "../../../config/axiosConfig";
import Cookies from "js-cookie";
import { CircularProgress } from "@mui/material";

const DriverLicenceForm = ({ setActiveStep }) => {
  const [driverLicenceImage, setDriverLicenceImage] = useState([]);
  const [loading, setLoading] = useState(false);
  return (
    <div className="w-full h-screen flex flex-col justify-start items-center bg-[#F1F5F9] text-black font-extralight pt-10">
      <div className="w-11/12 h-auto flex flex-col justify-center items-start gap-10 bg-[#E2E8F0] rounded-md p-8">
        <div className="flex flex-row justify-center items-end gap-2">
          <h1 className="text-xl uppercase max-sm:text-sm">Register</h1>
          <p className="text-xl text-[#1976D2] uppercase max-sm:text-sm">
            Driver Licence
          </p>
        </div>
        <Formik
          initialValues={{
            driver_licence_image: "",
            driver_licence: "",
            expiration: "",
          }}
          validate={(values) => {
            const errors = {};

            if (!values.driver_licence) {
              errors.driver_licence = "Please enter driver licence number";
            } else if (!/^[0-9]+$/.test(values.driver_licence)) {
              errors.driver_licence = "Should only contain numbers 0 - 9";
            }

            if (!values.expiration) {
              errors.expiration = "Please enter expiration";
            }

            return errors;
          }}
          onSubmit={async (values) => {
            try {
              values.driver_licence_image = driverLicenceImage;
              if (values.driver_licence_image.length === 0) {
                AboutMessage({
                  status: "error",
                  message: "Please upload file driver licence image",
                });
                return;
              }
              setLoading(true);
              const token = Cookies.get("token");
              const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
              const endpoint = process.env.REACT_APP_DRIVER_LINCENCE_STORE_URL;
              const axiosInstance = axiosConfig(baseURL, token);

              const config = {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              };

              const response = await axiosInstance.post(
                endpoint,
                values,
                config
              );
              if (response.status === 200) {
                setActiveStep("bank-info");
              }
            } catch (error) {
              if (error.response) {
                const errorsObject = error.response.data.message;
                Object.entries(errorsObject).forEach(([key, errorMessages]) => {
                  errorMessages.forEach((errorMessage) => {
                    AboutMessage({ status: "error", message: errorMessage });
                  });
                });
              }
            } finally {
              setLoading(false);
            }
          }}
        >
          {({ setFieldValue }) => (
            <Form className="w-full h-auto flex flex-col gap-2 font-medium">
              <ImageUploader
                title={"Driver Licence Image"}
                selectedFiles={driverLicenceImage}
                setSelectedFiles={setDriverLicenceImage}
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="driver_licence"
                minLength="0"
                maxLength="255"
                placeholder="Driver Licence Number"
              />
              <MaskedInput
              id="datefield"
                className={styles.stylefield}
                mask="1111/11/11"
                placeholder="Expiration yyyy/mm/dd"
                name="expiration"
                value="expiration"
                onChange={(e) => {
                  const result = e.target.value.replace(/\D/g, "");
                  setFieldValue("expiration", result);
                }}
              />
              <ErrorMessage
                name="expiration"
                component="div"
                className="w-full h-auto text-sm text-red-600 font-extralight max-sm:text-xs"
              />
              <div className="w-full h-auto flex flex-row justify-end items-center gap-1">
                <button
                  type="button"
                  className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-extralight shadow-md max-sm:text-xs max-md:text-sm"
                  onClick={() =>
                    AboutMessage({
                      status: "",
                      message: "This section will be activated soon",
                    })
                  }
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="w-1/2 h-auto flex flex-row justify-center items-center px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-extralight shadow-md max-sm:text-xs max-md:text-sm"
                >
                  {loading ? (
                    <CircularProgress size={16} sx={{ color: "black" }} />
                  ) : (
                    "Next"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default DriverLicenceForm;
