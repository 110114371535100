import Swal from "sweetalert2";
import NotificationSound from "../../assets/sound/notification-sound.wav";

const playNotificationSound = () => {
  const audio = new Audio(NotificationSound);
  audio.play();
  if (navigator.vibrate) {
    navigator.vibrate(400);
  }
};

const AboutMessage = ({ status, message ,darkMode}) => {
  const colorStyle = darkMode ? "#000" : "#fff";
  const backgroundStyle = darkMode ? "#E2E8F0" : "#1E293B";

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
    // timerProgressBar: true,
    color: colorStyle,
    background: backgroundStyle,
    // showCloseButton: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
      playNotificationSound();
    },
  });

  Toast.fire({
    icon: status,
    title: message,
    customClass: {
      popup: "colored-toast",
    },
  });
};

export default AboutMessage;