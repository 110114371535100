import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import LoginForm from "../components/template/form/LoginForm";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Spinner from "../components/module/Spinner";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = Cookies.get("token");
    token && navigate("/register");
    setLoading(false);
  }, [navigate]);

  if (loading) {
    return (
      <div className="h-screen w-full flex flex-col justify-center items-center bg-[#F1F5F9]">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="w-screen h-screen flex justify-center items-center bg-center bg-cover bg-bg">
        <div
          id="bg_login"
          className="h-full w-full flex flex-col justify-center items-center p-10 gap-4 font-medium cursor-default"
        >
          <h1 className="text-2xl text-amber-400 uppercase">Atoz Drivers</h1>
          <p className="text-base text-white">Login Form</p>
          <LoginForm />
        </div>
      </div>
    </>
  );
};

export default Login;
