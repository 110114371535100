import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import CustomStepper from "../components/module/CustomStepper";
import RegisterForm from "../components/template/form/RegisterForm";
import PersonalInfoForm from "../components/template/form/PersonalInfoForm";
import TruckInfoForm from "../components/template/form/TruckInfoForm";
import DriverLicenceForm from "../components/template/form/DriverLicenceForm";
import BankInfoForm from "../components/template/form/BankInfoForm";
import PhoneVerifyForm from "../components/template/form/PhoneVerifyForm";
import SignContractForm from "../components/template/form/SignContractForm";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../config/axiosConfig";
import Spinner from "../components/module/Spinner";

const Register = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = process.env.REACT_APP_PERSONAL_INFO_URL;
      const axiosInstance = axiosConfig(baseURL, token);

      try {
        if (token) {
          const response = await axiosInstance.get(endpoint);
          if (response.data.data.register_level_title === "complete") {
            navigate("/");
          } else {
            setActiveStep(response.data.data.register_level_title);
          }
          setLoading(false);
        } else {
          setActiveStep(0);
          setLoading(false);
        }
      } catch (error) {
        if(error.response.data.message === "Please confirm your email"){
          setShowModal(true);
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [setActiveStep, navigate]);

  if (loading) {
    return (
      <div className="h-screen w-full flex flex-col justify-center items-center bg-[#F1F5F9]">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Register</title>
      </Helmet>
      <div>
        {activeStep === 0 ? (
          <div className="w-screen h-screen flex justify-center items-center bg-center bg-cover bg-bg">
            <div
              className="h-full w-full flex flex-col justify-center items-center p-10 gap-4 font-medium cursor-default"
              id="bg_register"
            >
              <h1 className="text-2xl text-amber-400 uppercase">
                Atoz Drivers
              </h1>
              <p className="text-base text-white">Register in form</p>
              <RegisterForm setActiveStep={setActiveStep} showModal={showModal} setShowModal={setShowModal} />
            </div>
          </div>
        ) : activeStep === "personal-info" ? (
          <>
            <CustomStepper activeStep={activeStep} />
            <PersonalInfoForm setActiveStep={setActiveStep} />
          </>
        ) : activeStep === "truck-info" ? (
          <>
            <CustomStepper activeStep={activeStep} />
            <TruckInfoForm setActiveStep={setActiveStep} />
          </>
        ) : activeStep === "driver-licence" ? (
          <>
            <CustomStepper activeStep={activeStep} />
            <DriverLicenceForm setActiveStep={setActiveStep} />
          </>
        ) : activeStep === "bank-info" ? (
          <>
            <CustomStepper activeStep={activeStep} />
            <BankInfoForm setActiveStep={setActiveStep} />
          </>
        ) : activeStep === "phone-verify" ? (
          <>
            <CustomStepper activeStep={activeStep} />
            <PhoneVerifyForm setActiveStep={setActiveStep} />
          </>
        ) : activeStep === "sign-contract" ? (
          <>
            <CustomStepper activeStep={activeStep} />
            <SignContractForm setActiveStep={setActiveStep} />
          </>
        ) : null}
      </div>
    </>
  );
};

export default Register;
