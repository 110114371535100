import { FaGlobeAsia } from "react-icons/fa";
import { FaTruckFast } from "react-icons/fa6";
import { FaBoxOpen, FaTruckLoading } from "react-icons/fa";

const dataCards = [
  {
    icon: <FaTruckFast className="text-6xl max-sm:text-4xl" />,
    txt: "Choose Form our fleet",
  },
  {
    icon: <FaBoxOpen className="text-6xl max-sm:text-4xl" />,
    txt: "For smaller goods",
  },
  {
    icon: <FaTruckLoading className="text-6xl max-sm:text-4xl" />,
    txt: "Access for air transport",
  },
  {
    icon: <FaGlobeAsia className="text-6xl max-sm:text-4xl" />,
    txt: "All Country",
  },
];

export default dataCards;
