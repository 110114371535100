import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDarkMode } from "../../redux_toolkit/features/darkModeSlice";
import Notification from "../module/Notification";
import Spinner from "../module/Spinner";
import { Helmet } from "react-helmet";
// import Pusher from "pusher-js";
// import AboutMessage from "../module/AboutMessage";
// import "firebase/compat/messaging";
// import fetchPusherOptions from "../../utils/fetchPusherOptions";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.darkMode);
  const [loading, setLoading] = useState(true);
  const [viewportType, setViewportType] = useState(true);

  const themeColor = darkMode ? "#111827" : "#F1F5F9";

  useEffect(() => {
    const initializeDarkMode = async () => {
      const darkModeLocalStorage = localStorage.getItem("darkMode");
      if (darkModeLocalStorage) {
        dispatch(setDarkMode(JSON.parse(darkModeLocalStorage)));
      }
    };
    initializeDarkMode();
  }, [dispatch]);

  useEffect(() => {
    const checkWindowSize = () => {
      setViewportType(window.innerWidth > 767);
    };

    checkWindowSize();
    window.addEventListener("resize", checkWindowSize);
    setLoading(false);
    return () => {
      window.removeEventListener("resize", checkWindowSize);
    };
  }, []);

  // useEffect(() => {
  //   const initializePusher = async () => {
  //     Pusher.logToConsole = true;
  //     const pusher = new Pusher("cbd20b1aeb012508a8c8", {
  //       cluster: "mt1",
  //     });

  //     const channel = pusher.subscribe("my-channel");
  //     channel.bind("my-event", function (data) {
  //       AboutMessage({ status: "", message: data, darkMode });
  //     });

  //     return () => {
  //       channel.unbind_all();
  //       channel.unsubscribe();
  //     };
  //   };
  //   initializePusher();
  // }, [darkMode]);

  // useEffect(() => {
  //   const fetchPusherOpts = async () => {
  //     fetchPusherOptions();
  //   };
  //   fetchPusherOpts();
  // }, []);

  if (loading) {
    <div
      className={`h-screen w-full flex flex-col justify-start items-center pt-52 ${
        darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
      }`}
    >
      <Spinner />
    </div>;
  }
  if (viewportType) {
    return (
      <div className="h-screen w-full flex flex-col justify-center items-center bg-[#ffff] text-black"></div>
    );
  } else {
    return (
      <>
        <Helmet>
          <meta name="theme-color" content={themeColor} />
        </Helmet>
        <Notification />
        {children}
      </>
    );
  }
};

export default Layout;
