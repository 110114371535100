import { useState } from "react";
import { Field, ErrorMessage } from "formik";
import { VscEye } from "react-icons/vsc";
import { VscEyeClosed } from "react-icons/vsc";

const PasswordField = ({ name, minLength, maxLength, placeholder }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="w-full flex flex-col justify-start items-center gap-1 font-extralight">
      <div className="w-full h-auto flex flex-row justify-center items-center rounded-md bg-[#111827a6]">
        <Field
        className="w-5/6 h-full flex flex-row justify-start items-center rounded-l-md p-3 text-white text-base outline-none bg-[#00000000] placeholder:text-white max-sm:text-xs"
        type={showPassword ? "text" : "password"}
        name={name}
        minLength={minLength}
        maxLength={maxLength}
        placeholder={placeholder}
        />
        <button
        type="button"
        className="w-1/6 h-full flex flex-row justify-end items-center pr-3 text-white text-xl rounded-r-md outline-none max-md:text-base"
        onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <VscEyeClosed /> : <VscEye />}
        </button>
      </div>
      <ErrorMessage
      name={name}
      component="div"
      className="w-full h-auto text-sm text-red-600 max-sm:text-xs"
      />
    </div>
  );
};

export default PasswordField;
