import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaAngleRight } from "react-icons/fa6";

const ReportCard = ({
  id,
  created_at,
  first_name,
  last_name,
  description,
  report_type
}) => {
  const darkMode = useSelector((state) => state.darkMode);
  return (
    <Link
      to={`/loads/report/${id}`}
      className={`w-4/12 h-auto flex flex-col justify-center items-start gap-4 rounded-md p-4 cursor-pointer font-extralight text-sm max-md:w-full ${
        darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
      }`}
    >
      <div className="w-full h-auto flex flex-row justify-between items-center ">
        <p className="cursor-default text-[#a2a6ad] max-md:text-sm">
          Posted on {created_at}
        </p>
        <FaAngleRight className="text-[#a2a6ad]" />
      </div>
      <div className="flex flex-col justify-start items-start gap-1 font-normal">
        <div className="flex flex-row justify-start items-center gap-1">
          <span>Full Name :</span>
          <p>
            <span>{first_name}</span>
            <span>{last_name}</span>
          </p>
        </div>
        <p>
          <span>Description : </span>
          {description}
        </p>
        <p>
          <span>Report Type : </span>
          {report_type}
        </p>
      </div>
    </Link>
  );
};

export default ReportCard;
